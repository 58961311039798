export default defineAppConfig({
  ui: {
    primary: "green",
    notifications: {
      position: "top-0 bottom-auto",
    },
    icons: {
      dynamic: true,
    },
  },
});

<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<script setup lang="ts">
const { $sentrySetUser } = useNuxtApp();
const user = useSupabaseUser();

watchEffect(() => {
  if (user.value) {
    if (process.client) {
      $sentrySetUser({ id: user.value?.id, email: user.value?.email });
    }
  }
});
</script>

<style lang="scss" scoped></style>

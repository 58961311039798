import { default as change_45passwordCl4xCMHGgGMeta } from "/vercel/path0/pages/change-password.vue?macro=true";
import { default as create_45accountnCD2YNpolvMeta } from "/vercel/path0/pages/create-account.vue?macro=true";
import { default as _91id_93U2wGImaWVWMeta } from "/vercel/path0/pages/dashboard/prestataire/[id].vue?macro=true";
import { default as indexOp1ce6H8QlMeta } from "/vercel/path0/pages/dashboard/prestataire/index.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as _91id_93sPKBYIfWVWMeta } from "/vercel/path0/pages/logement/[id].vue?macro=true";
import { default as logint0AWlhQgM0Meta } from "/vercel/path0/pages/login.vue?macro=true";
import { default as mes_45missionsDgLsQ5cuRyMeta } from "/vercel/path0/pages/mes-missions.vue?macro=true";
import { default as _91id_93rjRy8jnF5iMeta } from "/vercel/path0/pages/mission/[id].vue?macro=true";
import { default as _91id_93bLoQQH2aS1Meta } from "/vercel/path0/pages/proposition/[id].vue?macro=true";
import { default as propositions3bfHYmffwwMeta } from "/vercel/path0/pages/propositions.vue?macro=true";
import { default as reset_45password7wK2GPZgVKMeta } from "/vercel/path0/pages/reset-password.vue?macro=true";
import { default as utilssjXyT3kOWEMeta } from "/vercel/path0/pages/utils.vue?macro=true";
export default [
  {
    name: change_45passwordCl4xCMHGgGMeta?.name ?? "change-password",
    path: change_45passwordCl4xCMHGgGMeta?.path ?? "/change-password",
    meta: change_45passwordCl4xCMHGgGMeta || {},
    alias: change_45passwordCl4xCMHGgGMeta?.alias || [],
    redirect: change_45passwordCl4xCMHGgGMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/change-password.vue").then(m => m.default || m)
  },
  {
    name: create_45accountnCD2YNpolvMeta?.name ?? "create-account",
    path: create_45accountnCD2YNpolvMeta?.path ?? "/create-account",
    meta: create_45accountnCD2YNpolvMeta || {},
    alias: create_45accountnCD2YNpolvMeta?.alias || [],
    redirect: create_45accountnCD2YNpolvMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/create-account.vue").then(m => m.default || m)
  },
  {
    name: _91id_93U2wGImaWVWMeta?.name ?? "dashboard-prestataire-id",
    path: _91id_93U2wGImaWVWMeta?.path ?? "/dashboard/prestataire/:id()",
    meta: _91id_93U2wGImaWVWMeta || {},
    alias: _91id_93U2wGImaWVWMeta?.alias || [],
    redirect: _91id_93U2wGImaWVWMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/dashboard/prestataire/[id].vue").then(m => m.default || m)
  },
  {
    name: indexOp1ce6H8QlMeta?.name ?? "dashboard-prestataire",
    path: indexOp1ce6H8QlMeta?.path ?? "/dashboard/prestataire",
    meta: indexOp1ce6H8QlMeta || {},
    alias: indexOp1ce6H8QlMeta?.alias || [],
    redirect: indexOp1ce6H8QlMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/dashboard/prestataire/index.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93sPKBYIfWVWMeta?.name ?? "logement-id",
    path: _91id_93sPKBYIfWVWMeta?.path ?? "/logement/:id()",
    meta: _91id_93sPKBYIfWVWMeta || {},
    alias: _91id_93sPKBYIfWVWMeta?.alias || [],
    redirect: _91id_93sPKBYIfWVWMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/logement/[id].vue").then(m => m.default || m)
  },
  {
    name: logint0AWlhQgM0Meta?.name ?? "login",
    path: logint0AWlhQgM0Meta?.path ?? "/login",
    meta: logint0AWlhQgM0Meta || {},
    alias: logint0AWlhQgM0Meta?.alias || [],
    redirect: logint0AWlhQgM0Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/login.vue").then(m => m.default || m)
  },
  {
    name: mes_45missionsDgLsQ5cuRyMeta?.name ?? "mes-missions",
    path: mes_45missionsDgLsQ5cuRyMeta?.path ?? "/mes-missions",
    meta: mes_45missionsDgLsQ5cuRyMeta || {},
    alias: mes_45missionsDgLsQ5cuRyMeta?.alias || [],
    redirect: mes_45missionsDgLsQ5cuRyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/mes-missions.vue").then(m => m.default || m)
  },
  {
    name: _91id_93rjRy8jnF5iMeta?.name ?? "mission-id",
    path: _91id_93rjRy8jnF5iMeta?.path ?? "/mission/:id()",
    meta: _91id_93rjRy8jnF5iMeta || {},
    alias: _91id_93rjRy8jnF5iMeta?.alias || [],
    redirect: _91id_93rjRy8jnF5iMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/mission/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93bLoQQH2aS1Meta?.name ?? "proposition-id",
    path: _91id_93bLoQQH2aS1Meta?.path ?? "/proposition/:id()",
    meta: _91id_93bLoQQH2aS1Meta || {},
    alias: _91id_93bLoQQH2aS1Meta?.alias || [],
    redirect: _91id_93bLoQQH2aS1Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/proposition/[id].vue").then(m => m.default || m)
  },
  {
    name: propositions3bfHYmffwwMeta?.name ?? "propositions",
    path: propositions3bfHYmffwwMeta?.path ?? "/propositions",
    meta: propositions3bfHYmffwwMeta || {},
    alias: propositions3bfHYmffwwMeta?.alias || [],
    redirect: propositions3bfHYmffwwMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/propositions.vue").then(m => m.default || m)
  },
  {
    name: reset_45password7wK2GPZgVKMeta?.name ?? "reset-password",
    path: reset_45password7wK2GPZgVKMeta?.path ?? "/reset-password",
    meta: reset_45password7wK2GPZgVKMeta || {},
    alias: reset_45password7wK2GPZgVKMeta?.alias || [],
    redirect: reset_45password7wK2GPZgVKMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: utilssjXyT3kOWEMeta?.name ?? "utils",
    path: utilssjXyT3kOWEMeta?.path ?? "/utils",
    meta: utilssjXyT3kOWEMeta || {},
    alias: utilssjXyT3kOWEMeta?.alias || [],
    redirect: utilssjXyT3kOWEMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/utils.vue").then(m => m.default || m)
  }
]
import revive_payload_client_4sVQNw7RlN from "/vercel/path0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/vercel/path0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import supabase_client_I4Hv4qpJHd from "/vercel/path0/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.mjs";
import payload_client_yVLowv6hDl from "/vercel/path0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_client_Eorgdr12jA from "/vercel/path0/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import auth_redirect_hxxEaFfrIx from "/vercel/path0/node_modules/@nuxtjs/supabase/dist/runtime/plugins/auth-redirect.mjs";
import colors_244lXBzhnM from "/vercel/path0/node_modules/@nuxt/ui/dist/runtime/plugins/colors.mjs";
import plugin_client_i8AMfKeYnY from "/vercel/path0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import chunk_reload_client_UciE0i6zes from "/vercel/path0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/vercel/path0/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import sentry_client_shVUlIjFLk from "/vercel/path0/plugins/sentry.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  supabase_client_I4Hv4qpJHd,
  payload_client_yVLowv6hDl,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  pwa_client_Eorgdr12jA,
  auth_redirect_hxxEaFfrIx,
  colors_244lXBzhnM,
  plugin_client_i8AMfKeYnY,
  chunk_reload_client_UciE0i6zes,
  check_outdated_build_client_8vK7RkfGxZ,
  sentry_client_shVUlIjFLk
]